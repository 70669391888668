export const chartCfg = {
    selector: 'svg-chart',
    margin: 26.6,
    axis: {
        selector: 'axis',
        dataRange: {
            start: -7,
            end: 7
        }
    },
    zoom: {
        selector: 'zoom-area',
        min: 1,
        max: 8,
        stepUp: 1.2,
        stepDown: 0.8,
    },
    cssSelector: {
        grid: 'grid',
        active: 'active',
        group: 'group',
        insulation: 'insulation',
        wire: 'wire',
        foil: 'foil',
        twisted: 'twisted',
        jacket: 'jacket',
        braid: 'braid',
        ftp: 'ftp',
    }
}

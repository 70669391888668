import { useState } from "react";

interface ReturnType {
    open: boolean;
    setOpen: (value: boolean) => void;
    handleOpen: () => void;
    handleClose: () => void;
}

export function useDialogManager(initialState=false): ReturnType {
    const [open, setOpen] = useState<boolean>(initialState);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return {
        open,
        setOpen,
        handleOpen,
        handleClose,
    };
}

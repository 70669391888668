import { CableChart } from './CableChart';
import { AppState, CableComponent } from '../shared/types';
import { useApp } from '../../app/AppContext';
import { useCable } from './CableContext';

export function MainPanel(): JSX.Element {
    const {state: appState, dispatch: dispatchState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    function handleChange(state: AppState) {
        dispatchState({
            type: 'set config',
            payload: {...state}
        });
    }

    function handleCableChange(state: Array<CableComponent>) {
        dispatchCable({
            type: 'update cable',
            payload: state
        });
    }

    function handleComponentUpdate(component: CableComponent) {
        dispatchCable({type: 'update component', payload: [component]});
    }


    return (
        <CableChart
            appState={appState}
            onChange={handleChange}
            cableState={cableState}
            onCableChange={handleCableChange}
            onComponentUpdate={handleComponentUpdate}
        />
    )
}

import { Selection } from 'd3-selection';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';
import * as d3 from 'd3';

interface Props {
    svg: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    isActive: boolean;
    position: [number, number];
    onDragEnd: (component: CableComponent, translation: [number, number]) => void;
    onSelect: (event: any, selectedId: string, parentId?: string) => void;
}

const translateXY = (node: SVGGElement): [number, number] => {
    if (!node) return [0,0];
    const matrix = node.transform.baseVal.getItem(0).matrix;
    const {e: translateX, f: translateY} = matrix;
    return [translateX, translateY];
}

export function Group({
    svg,
    component,
    position,
    isActive,
    onDragEnd,
    onSelect,
}: Props): Selection<any, any, any, any> {
    const { group: groupSelector } = chartCfg.cssSelector;
    const id = `${component.id}`;

    const group =
        svg
        .selectAll(`.${groupSelector}#${id}`)
        .data([component])
        .join(
            enter => enter
            .append('g')
            .attr('id', id)
            .attr('class', `${groupSelector} ${isActive ? chartCfg.cssSelector.active : ''}`)
            .attr('transform', `translate(${position[0]},${position[1]})`)
            .attr('cursor', 'move')
            .on('mousedown', (event: any, d) => onSelect(event, d.id, d.parentId))
            ,
            exit => exit
            .remove()
        )

    // @ts-ignore
    group.call(d3.drag()
        .on('drag', (event) => {
            const node = group.node();
            const translate = translateXY(node as SVGGElement);
            const x = translate[0] + event.dx;
            const y = translate[1] + event.dy;
            group.attr('transform', `translate(${x}, ${y})`);
        })
        .on('end', () => {
            const node = group.node();
            const translate = translateXY(node as SVGGElement);
            onDragEnd(component, translate);
        })
    )

    return group;
}



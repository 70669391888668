export const labelCss = `
    block
`;

const input = `
    bg-white text-black border-0
    focus:outline-none focus:ring-0 focus:ring-offset-0
    disabled:bg-gray-400
`;

const basicInput = `
    ${input} px-3 py-2
`;

export const inputSmCss = `
    ${input} px-2 py-0 rounded-md
`;

export const inputCss = `
    ${basicInput} rounded-md
`;

export const inputColorCss = `bg-white border-0 rounded-md h-8 w-8 disabled:opacity-30`;

// image of the check mark is defined in index.scss
const basicCheckbox = `
    h-6 w-6 shrink-0 cursor-pointer appearance-none rounded
`;

export const checkboxCss = `
    ${basicCheckbox} bg-white mr-4 disabled:opacity-30
`;

export const selectCss = `
    bg-white text-black inline-flex flex-shrink-0 cursor-pointer select-none
    appearance-none rounded-md pl-2 pr-10 py-1 leading-loose outline-0
    bg-no-repeat bg-[right_.75rem_center] bg-[length:16px_12px]
    disabled:bg-gray-400
`;

export const propertiesForm = `flex-1 flex flex-col`;
export const propertiesContainer = `flex-1 flex flex-col gap-4 px-3 pt-2 flex-auto overflow-y-auto h-[0]`;

import * as d3 from 'd3';
import { chartCfg } from '../CableChartConfig';
import { Selection } from 'd3-selection';
import { ScaleLinear } from 'd3-scale';
import { CableComponent } from '../../shared/types';

interface Props {
    svgEl: Selection<SVGSVGElement, CableComponent, any, any>;
    boardSize: number;
    axisType: 'x' | 'y';
}

export function Axis({
    svgEl, 
    boardSize,
    axisType,
}: Props): ScaleLinear<number, number, never> {
    const { margin, axis: {dataRange, selector} } = chartCfg;

    let
        axisXPosFromTo: [number, number],
        axisYPosFromTo: [number, number],
        tickLabelPosXY: Array<string>;
    if (axisType === 'x') {
        axisXPosFromTo = [margin, boardSize];
        axisYPosFromTo = [0, boardSize - margin];
        tickLabelPosXY = ['.1em', '.8em'];
    }
    else { // y
        axisXPosFromTo = [boardSize - margin, 0];
        axisYPosFromTo = [margin, 0];
        tickLabelPosXY = ['-0.5em', '.8em'];
    }

    const axis =
        d3
        .scaleLinear()
        .domain([dataRange.start, dataRange.end])
        .range(axisXPosFromTo);

    let
        axisZeroPoint: number,
        axisDrawFunc;
    if (axisType === 'x') {
        axisZeroPoint = axis(0) - margin;
        axisDrawFunc = d3.axisBottom(axis);
    }
    else { // y
        axisZeroPoint = axis(0) + margin;
        axisDrawFunc = d3.axisLeft(axis);
    }

    svgEl
    .append('g')
    .attr('transform', `translate(${axisYPosFromTo[0]}, ${axisYPosFromTo[1]})`)
    .attr('class', selector)
    .call(axisDrawFunc)

    .selectAll('text') // `text` has already been created, use it to add tick labels
    .append('tspan')
    .attr('x', 0)
    .attr('dx', tickLabelPosXY[0])
    .attr('dy', tickLabelPosXY[1])
    .text('mm');

    let
        lineX: [number, number],
        lineY: [number, number];
    if (axisType === 'x') {
        lineX = [margin, boardSize];
        lineY = [axisZeroPoint, axisZeroPoint];
    }
    else {
        lineX = [axisZeroPoint, axisZeroPoint];
        lineY = [0, boardSize - margin];
    }

    // dashed line that marks the center
    svgEl
    .append('line')
    .attr('x1', lineX[0])
    .attr('x2', lineX[1])
    .attr('y1', lineY[0])
    .attr('y2', lineY[1])
    .attr('class', selector)
    .style('stroke', 'rgba(255,255,255,0.6)')
    .style('stroke-dasharray', '5,5')

    return axis;
}

import { iconBtnFlexCss } from '../../../theme/css/button';
import { Menu, MenuItem } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';
import { ButtonMenuItem } from '../types';
import { generateKey } from '../services/Utils';

interface Props {
    buttonLabel: ReactNode;
    menuItems: Array<ButtonMenuItem>;
    buttonCss?: string;
}

export function ButtonMenu({
    buttonLabel,
    menuItems,
    buttonCss=iconBtnFlexCss
}: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    function handleMenuItemClick(
        event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLLIElement>,
        item: ButtonMenuItem
    ) {
        event.preventDefault();
        event.stopPropagation();
        item.onClick();
        handleClose();
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <button
                className={buttonCss}
                onClick={handleClick}
            >
                <>{buttonLabel}</>
            </button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    menuItems.map(item => (
                        <MenuItem
                            key={generateKey()}
                            onClick={(e) => handleMenuItemClick(e, item)}
                            className={`flex gap-2`}
                        >
                            {item.icon}
                            {item.label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}

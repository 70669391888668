export const borderColor = `border-white border-opacity-10`;

export const borderCss = `
    border ${borderColor}
`;

export const borderBottomCss = `
    border-b ${borderColor}
`;

export const borderTopCss = `
    border-t ${borderColor}
`;

export const borderRightCss = `
    border-r ${borderColor}
`;

import { AppState, CableComponent, ComponentType, JsonComponent, Unit } from '../types';
import {
    circleCoordinatesPx,
    generateKey,
    getStrandDiameter,
    isConvex,
    strandValuesPerLevel
} from './Utils';
import { buildStrandsLayout } from '../../properties/forms/WireForm';

export function findNodeById(id: string, treeData: Array<CableComponent> = []): CableComponent | undefined {
    for (const node of treeData) {
        if (node.id === id) return node;
        if (node.children.length > 0) {
            const childNode = findNodeById(id, node.children);
            if (childNode) return childNode;
        }
    }
}

export function findNodes(selectedIds: Array<string> = [], treeData: Array<CableComponent> = []): Array<CableComponent> {
    return treeData.reduce((acc: Array<CableComponent>, item) => {
        if (selectedIds.includes(item.id)) acc.push(item);
        if (item.children) acc.push(...findNodes(selectedIds, item.children));
        return acc;
    }, [])
}

function deleteSingleNode(nodeIds: Array<string>, node: CableComponent): Array<CableComponent> {
    const selected = nodeIds.find(id => id === node.id);
    return selected
        ? []
        : [{ ...node, children: deleteNodes(nodeIds, node.children) }]
}

export function deleteNodes(nodeIds: Array<string>, components: Array<CableComponent> = []): Array<CableComponent> {
    return components.flatMap(node => deleteSingleNode(nodeIds, node))
}

export function updateNode(
    newNode: CableComponent,
    nodeData: Array<CableComponent>
): Array<CableComponent> {
    return nodeData.map(({id, children, ...rest}) => {
        return (id === newNode.id)
            ? {
                ...newNode,
                ...(children && {children: updateNode(newNode, children)})
            }
            : {
                id,
                ...rest,
                ...(children && {children: updateNode(newNode, children)})
            }
    })
}

interface wrapSelectedProps extends AppState {
    defaults: Partial<CableComponent>;
    defaultProperties: CableComponent;
    dataTree: Array<CableComponent>;
}

export function wrapSelectedNodes({
    defaults={},
    defaultProperties,
    dataTree=[],
    selectedCompIds,
}: wrapSelectedProps): Array<CableComponent> | undefined {
    const prevSelectedCompIds = [...selectedCompIds];
    const selectedNodes = findNodes(prevSelectedCompIds, dataTree);
    if (selectedNodes.length === 0) return;

    // delete current from tree
    const newTree = deleteNodes(prevSelectedCompIds, dataTree);

    selectedNodes.forEach(node => node.parentId = defaults.id);

    // create new node with removed children
    const foilComp: CableComponent = {
        ...defaultProperties,
        ...defaults,
        chartData: {
            ...defaultProperties.chartData,
        },
        children: selectedNodes
    };

    return [...newTree, foilComp];
}

export function normalizeImportData(
    jsonComponent: Array<JsonComponent>,
    level: number,
    appData: AppState,
    parentId?: string,
): Array<CableComponent | {[key: string]: any}> {
    return jsonComponent.map(({component_type, name, components, ...rest}) => {
        const id = `${component_type.replaceAll(' ','-')}-${generateKey()}`;
        const { axisX, axisY } = appData;
        if (!axisX || !axisY)  return {};
        const absX = isNaN(rest.x) ? 0 : axisX(rest.x);
        const absY = isNaN(rest.y) ? 0 : axisY(rest.y);

        switch (component_type) {
            case ComponentType.JACKET:
            case ComponentType.BRAID:
                return {
                    id,
                    value: name,
                    type: component_type,
                    properties: {
                        colors: [rest.color],
                        thickness: rest.thickness,
                        x: rest.x,
                        y: rest.y,
                    },
                    nodes: [
                        {
                            x: 0,
                            y: 0,
                            radius: rest.radius
                        }
                    ],
                    chartData: {
                        compAbsolutePos: [absX, absY],
                    },
                    depth: level,
                    parentId,
                    ...(components && {children: normalizeImportData(components, ++level, appData, id)})
                }
            case ComponentType.FOIL:
                return {
                    id,
                    value: name,
                    type: ComponentType.FOIL,
                    properties: {
                        colors: [rest.color],
                        thickness: rest.thickness,
                    },
                    nodes: [],
                    chartData: {
                        compAbsolutePos: [absX, absY],
                        hullData: [],
                    },
                    depth: level,
                    parentId,
                    ...(components && {children: normalizeImportData(components, ++level, appData, id)})
                }
            case ComponentType.TWISTED_PAIR:
                return {
                    id,
                    value: name,
                    type: ComponentType.TWISTED_PAIR,
                    properties: {
                        thickness: 0.08,
                    },
                    nodes: [],
                    chartData: {
                        compAbsolutePos: [absX, absY],
                        hullData: [],
                    },
                    depth: level,
                    parentId,
                    ...(components && {children: normalizeImportData(components, ++level, appData, id)})
                }
            case ComponentType.WIRE:
                const diameter = rest.copper.radius * 2;
                const nodes = buildStrandsLayout(rest.copper.number_of_strands, diameter);
                const outer_insulation_diameter = rest.insulation.thickness === 0 ?
                    0 : (rest.insulation.radius * 2) + rest.insulation.thickness;

                return {
                    id,
                    value: name,
                    type: ComponentType.WIRE,
                    properties: {
                        colors: rest.insulation.colors,
                        wire: {
                            strands_total: rest.copper.number_of_strands,
                            strands_diameter: diameter,
                            strands_units: Unit.mm,
                            nominal_diameter: 1.00,
                            nominal_diameter_units: Unit.mm,
                            manually_edit_nom_diameter: false,
                            outer_insulation_diameter,
                            copper_color: rest.copper.color,
                        },
                        x: rest.x,
                        y: rest.y,
                        manually_edit_coordinates: false,
                    },
                    nodes,
                    chartData: {
                        compAbsolutePos: [absX, absY],
                    },
                    depth: level,
                    children: [],
                    parentId,
                }
            default:
                return {}
        }
    })
}

export function normalizeExportData(cableData: Array<CableComponent>, appData: AppState, tree: Array<CableComponent>): object {
    return cableData.map(node => {
        const {type, value, properties, nodes, children} = node;
        let x = properties.x;
        let y = properties.y;

        if (!isConvex(node) && node.depth >= 1 && appData.axisX && appData.axisY) {
            const coordinatesPx = circleCoordinatesPx(node, tree, [0, 0]);
            x = (coordinatesPx[0] - appData.axisX(0)) / appData.pixelsPerMm;
            y = (appData.axisY(0) - coordinatesPx[1]) / appData.pixelsPerMm;
        }

        switch (type) {
            case ComponentType.JACKET:
                return {
                    component_type: type,
                    name: value,
                    type: 'PVC',
                    color: properties.colors ? properties.colors[0] : '',
                    radius: nodes[0].radius,
                    thickness: properties.thickness,
                    x,
                    y,
                    ...(children && {components: normalizeExportData(children, appData, tree)})
                }
            case ComponentType.BRAID:
                return {
                    component_type: type,
                    name: value,
                    color: properties.colors ? properties.colors[0] : '',
                    radius: nodes[0].radius,
                    thickness: properties.thickness,
                    x,
                    y,
                    ...(children && {components: normalizeExportData(children, appData, tree)})
                }
            case ComponentType.FOIL:
                return {
                    component_type: type,
                    name: value,
                    color: properties.colors ? properties.colors[0] : '',
                    thickness: properties.thickness,
                    ...(children && {components: normalizeExportData(children, appData, tree)})
                }
            case ComponentType.TWISTED_PAIR:
                return {
                    component_type: 'twisted',
                    name: value,
                    ...(children && {components: normalizeExportData(children, appData, tree)})
                }
            case ComponentType.WIRE:
                const { wire } = properties;
                if (!wire) return {};
                const totalStrands = wire.strands_total ? +wire.strands_total : 0;
                const strandValues = strandValuesPerLevel(totalStrands);
                if (!strandValues) return {};
                const strandDiameter = getStrandDiameter(wire);
                const innerDiameter = strandValues.diameterMultiply * strandDiameter;
                const outerDiameter = wire.outer_insulation_diameter ? +wire.outer_insulation_diameter : 0;
                const innerRadius = (innerDiameter / 2);
                const colors = properties.colors?.filter(clr => typeof clr === 'string');

                return {
                    component_type: type,
                    name: value,
                    x,
                    y,
                    copper: {
                        number_of_strands: totalStrands,
                        radius: strandDiameter / 2,
                        color: wire.copper_color
                    },
                    insulation: {
                        type: 'PVC',
                        colors,
                        radius: innerRadius,
                        thickness: outerDiameter === 0 ? 0 : outerDiameter - innerDiameter
                    }
                }
            default:
                return {}
        }
    })
}

import { SortableTree, TreeItem, TreeItems } from 'dnd-kit-sortable-tree';
import { useCable } from '../cable/CableContext';
import { PropertiesTreeItem, TreeNode } from './PropertiesTreeItem';
import { ItemChangedReason } from 'dnd-kit-sortable-tree/dist/types';
import { useApp } from '../../app/AppContext';

export function PropertiesTree(): JSX.Element {
    const {dispatch: dispatchApp} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    function relativePosInGroup(items: TreeItems<TreeNode>, parent: TreeNode|null, group: TreeNode): any {
        return items.flatMap(node => {
            const { chartData, depth } = node;
            const { compAbsolutePos } = chartData; // child
            const level = parent ? parent.depth+1 : depth; // bug in depth, this calc fixes it
            let newChartData = { ...chartData };

            if (parent && level >= 1 && !chartData.groupRelativePos) { // inside a group
                const groupAbsolutePos = group.chartData.compAbsolutePos;
                const dx = compAbsolutePos[0] - groupAbsolutePos[0];
                const dy = compAbsolutePos[1] - groupAbsolutePos[1];

                newChartData = {
                    ...chartData,
                    groupRelativePos: [dx, dy],
                }
            }

            return {
                ...node,
                chartData: {
                    ...newChartData
                },
                ...(node.children && {children: relativePosInGroup(node.children, node, group)})
            }
        })
    }

    function getTopParent(item: TreeItem<any>): TreeNode {
        if (!item || !item.parent) return item;
        return getTopParent(item.parent);
    }

    function handleItemsChange(items: TreeItems<TreeNode>, reason: ItemChangedReason<TreeNode>) {
        if (reason.type === 'dropped') {
            if (reason.draggedFromParent) return; // cannot change existing groups
            const group = getTopParent(reason.droppedToParent);
            if (!group) return; // dropped item on self
            const cable = relativePosInGroup(items, null, group);
            dispatchCable({ type: 'update cable', payload: cable});
            dispatchApp({ type: 'set config', payload: {selectedCompIds: []} });
        }
        else {
            dispatchCable({ type: 'update cable', payload: items});
        }
    }


    return (
        <SortableTree
            items={cableState as TreeItems<TreeNode>}
            onItemsChanged={handleItemsChange}
            TreeItemComponent={PropertiesTreeItem}
            indentationWidth={40}
        />
    );
}

import { Selection } from 'd3-selection';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';
import { circlePosition } from '../../shared/services/Utils';

interface Props {
    container: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    pixelsPerMm: number;
    tree: Array<CableComponent>,
}

export function Jacket({
    container,
    component,
    pixelsPerMm,
    tree,
}: Props) {
    const { properties, nodes } = component;
    if (nodes.length === 0) return;
    const {colors, thickness} = properties;
    const {radius, x, y} = nodes[0];
    const color = colors ? colors[0] : '';
    const strokeWidth = thickness ? +thickness * pixelsPerMm : 0;
    const nodePos: [number, number] = [x * pixelsPerMm, y * pixelsPerMm];
    const position = circlePosition(component, nodePos, tree);

    container
    .append('circle')
    .attr('cx', position[0])
    .attr('cy', position[1])
    .attr('r',  pixelsPerMm * radius)
    .attr('class', `${chartCfg.cssSelector.jacket}`)
    .attr('fill', 'none')
    .attr('stroke', color)
    .attr('stroke-width', strokeWidth)
    .attr('stroke-location', 'inside')
    .attr('stroke-linejoin', 'round');
}



import { ReactNode } from 'react';
import { ScaleLinear } from 'd3-scale';


// --- general

export interface ButtonMenuItem {
    label: string;
    icon: ReactNode;
    onClick: () => void
}

export interface Axis {
    name: string;
    value: number;
}

export enum ComponentType {
    WIRE = 'wire',
    BRAID = 'braid',
    JACKET = 'jacket',
    FOIL = 'foil',
    TWISTED_PAIR = 'twisted',
}

export interface AwgMmUnitMap {
    mm: number;
    awg: number;
}

export enum Unit {
    mm = 'mm',
    awg = 'awg',
}

export enum Material {
    PVC = 'PVC'
}

export interface StrandLevel {
    level: number;
    diameterMultiply: number;
    strandsCount: {
        from: number;
        to: number
    }
}

export interface Node {
    radius: number,
    x: number;
    y: number;
}


// --- cable data

export interface WireProperties {
    strands_total: number;
    strands_diameter: number;
    strands_units: Unit;
    nominal_diameter: number;
    nominal_diameter_units: Unit;
    manually_edit_nom_diameter: boolean;
    outer_insulation_diameter: number;
    copper_color: string;
}

export interface Properties {
    colors: [string] | [string, string];
    wire: Partial<WireProperties>; // wire has specific properties. not optional because the behavior of the wire form.
    thickness?: number;
    material?: Material.PVC; // to be extended in the future
    x?: number; // for circles: abs. pos on axis, in mm
    y?: number;
    manually_edit_coordinates?: boolean;
}

export interface CableComponent {
    id: string;
    value: string; // => label. the component requires it to be called 'value'
    type: ComponentType;
    properties: Partial<Properties>; // configuration that can be changed by the user
    nodes: Array<Node>;              // strands/circle data
    chartData: {                     // extra info related with drawing the cable
        compAbsolutePos: [number, number];  // absolute position on axis
        groupRelativePos?: [number, number];// relative position inside a group
        hullData?: Array<[number, number]>; // only for foil/twisted pair
    };
    depth: number;
    children: Array<CableComponent>;
    parentId?: string;
}

export interface AppState {
    showGrid: boolean;
    showAxes: boolean;
    zoom: number;
    pixelsPerMm: number;
    axisCenter: [number, number];
    selectedCompIds: Array<string>;
    axisX?: ScaleLinear<number, number, never>,
    axisY?: ScaleLinear<number, number, never>,
}


// --- import

export interface JsonComponent {
    component_type: ComponentType; //'jacket' | 'braid' | 'wire' | 'foil' | 'twisted'
    name: string;
    components: Array<JsonComponent>;
    [key: string]: any;
}

export interface ImportJson {
    components: Array<JsonComponent>;
}

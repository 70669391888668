import { createContext, ReactNode, useContext, useReducer } from 'react';
import { AppState } from '../modules/shared/types';

type Action = {
    type: 'set config',
    payload: Partial<AppState>,
}

type Dispatch = (action: Action) => void
type Context = { state: AppState; dispatch: Dispatch } | undefined
type ProviderProps = { children: ReactNode }

const AppContext = createContext<Context>(undefined);

function appReducer(currentState: AppState, action: Action): AppState {
    switch (action.type) {
        case 'set config':
            return {
                ...currentState,
                ...action.payload,
            }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function AppProvider({children}: ProviderProps) {
    const initialState: AppState = {
        showAxes: true,
        showGrid: true,
        zoom: 0,
        selectedCompIds: [],
        pixelsPerMm: 0,
        axisCenter: [0, 0],
    };
    const [state, dispatch] = useReducer(appReducer, initialState);
    const value = {state, dispatch};

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

function useApp() {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useApp must be used within an AppProvider');
    }

    return context;
}

export { AppProvider, useApp }

import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement>{
    title: string
}

const defaultCss = 'text-picton-blue-500 mb-2 mt-1 border-b border-picton-blue-500 border-opacity-10 py-1';

export function SectionTitle({title, className=defaultCss, ...rest}: Props) {
    return (
        <div className={className} {...rest}>
            {title}
        </div>
    )
}

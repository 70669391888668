import { CableComponent } from '../../shared/types';
import { useApp } from '../../../app/AppContext';
import { useCable } from '../../cable/CableContext';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { inputColorCss, inputCss, labelCss, propertiesContainer, propertiesForm } from '../../../theme/css/form';
import { btnPrimaryCss } from '../../../theme/css/button';
import { findNodeById } from '../../shared/services/TreeDataManager';

export function FoilForm({foil}: {foil: CableComponent}): JSX.Element {
    const {state: appState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    const {
        register,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = useForm<CableComponent>({
        defaultValues: foil,
    });

    useEffect(() => {
        const selected = findNodeById(appState.selectedCompIds[0], cableState);
        reset(selected);
    }, [appState.selectedCompIds, cableState, reset])

    async function submitData(foilData: CableComponent) {
        dispatchCable({type: 'update component', payload: [foilData]});
    }


    return (
        <form
            onSubmit={handleSubmit(submitData)}
            className={propertiesForm}
        >
            <div className={propertiesContainer}>
                <div>
                    <label className={labelCss}>Thickness</label>
                    <div className={`flex gap-4 items-center mb-4 items-stretch`}>
                        <div>
                            <div>
                                <input
                                    className={inputCss}
                                    type="number"
                                    min={0}
                                    step={0.001}
                                    {...register('properties.thickness')}
                                />
                                <label className={`pl-4`}>mm</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={`flex gap-4 items-center mb-4 items-stretch`}>
                        <div>
                            <label className={labelCss}>Color</label>
                            <input
                                className={`${inputColorCss}`}
                                type="color"
                                {...register(`properties.colors.${0}`)}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div className="p-4 flex items-center justify-center gap-4">
                <button
                    type="submit"
                    className={`${btnPrimaryCss} px-8`}
                    disabled={!isDirty}
                >
                    Draw
                </button>
            </div>
        </form>
    );
}

import { chartCfg } from '../CableChartConfig';
import { Selection } from 'd3-selection';
import { ScaleLinear } from 'd3-scale';
import { CableComponent } from '../../shared/types';

interface Props {
    svgEl: Selection<SVGSVGElement, CableComponent, any, any>;
    boardSize: number;
    axisX: ScaleLinear<number, number, never>;
    axisY: ScaleLinear<number, number, never>;
}

export function Grid({
    svgEl,
    boardSize,
    axisX,
    axisY,
}: Props) {
    const grid = (g: any) => {
        g
        .attr('stroke', 'currentColor')
        .attr('stroke-opacity', 0.1)
        .call((g: any) => { // vertical
            g
            .append('g')
            .selectAll('line')
            .data(axisX.ticks())
            .join('line')
            .attr('x1', (d: any) => axisX(d))
            .attr('x2', (d: any) => axisX(d))
            .attr('y1', 0)
            .attr('y2', boardSize - chartCfg.margin)
        })
        .call((g: any) => { // horizontal
            g
            .append('g')
            .selectAll('line')
            .data(axisY.ticks())
            .join('line')
            .attr('y1', (d: any) => axisY(d))
            .attr('y2', (d: any) => axisY(d))
            .attr('x1', chartCfg.margin)
            .attr('x2', boardSize)
        })
    }

    svgEl
    .append('g')
    .attr('class', chartCfg.cssSelector.grid)
    .call(grid)
}

import { SectionTitle } from '../../shared/components/SectionTitle';
import {
    checkboxCss,
    inputColorCss,
    inputCss,
    labelCss,
    propertiesContainer,
    propertiesForm,
    selectCss
} from '../../../theme/css/form';
import { CableComponent, Node, Unit, WireProperties } from '../../shared/types';
import { useForm } from 'react-hook-form';
import { ReactNode, useEffect, useState } from 'react';
import {
    formatCircleData,
    getStrandDiameter,
    strandValuesPerLevel
} from '../../shared/services/Utils';
import { btnPrimaryCss } from '../../../theme/css/button';
import {
    awgStrandsDiameterMap,
    awgToMillimeterMap,
    numberOfStrands,
    strandDiameterAwg,
    unitsList
} from '../LookupTable';
import { useCable } from '../../cable/CableContext';
import { useApp } from '../../../app/AppContext';
import { findNodeById } from '../../shared/services/TreeDataManager';
import { chartCfg } from '../../cable/CableChartConfig';

function buildStrands(strandIndex: number, strandDiameter: number): { x: number, y: number } | undefined {
    if (strandIndex === 1) {
        return {x: 0, y: 0};
    }

    const strandValues = strandValuesPerLevel(strandIndex);
    if (!strandValues) {
        return;
    }

    const {level, strandsCount} = strandValues;
    const maxCirclesInRow = strandsCount.to - strandsCount.from + 1;
    const pivot = (level - 1) * strandDiameter; // * 2;
    const angle = (360 / maxCirclesInRow * (strandIndex - strandsCount.from)) * Math.PI / 180;
    const x = pivot * Math.cos(angle);
    const y = pivot * Math.sin(angle);

    return {x, y};
}

export function buildStrandsLayout(totalStrands: number, strandDiameter: number): Array<Node> {
    const nodes: Array<Node> = [];

    for (let j = 1; j <= totalStrands; j++) {
        const strandOnEachLevel = buildStrands(j, strandDiameter);
        if (!strandOnEachLevel) continue;

        nodes.push({
            radius: strandDiameter / 2,
            x: strandOnEachLevel.x,
            y: strandOnEachLevel.y,
        })
    }

    return nodes;
}

export function wirePropertiesData(wire?: Partial<WireProperties>): Array<Node> {
    if (!wire || !wire.strands_total) return [];
    const diameter = getStrandDiameter(wire);
    return buildStrandsLayout(+wire.strands_total, diameter);
}

export function decimalPlaces(actualNum: number, places=2): number {
    return +actualNum.toFixed(places);
}

const inputNumCss = `${inputCss} max-w-[115px]`;

function FormRow({children}: {children: ReactNode}): JSX.Element {
    return (
        <div className={`flex gap-4 items-center mb-4 items-stretch`}>
            {children}
        </div>
    )
}

function SelectOptions({options}: {options: Array<number|string>}): JSX.Element {
    return (
        <>
            {
                options.map(opt => <option key={opt} value={opt}>{opt}</option>)
            }
        </>
    )
}

export function WireForm({wire}: {wire: CableComponent}): JSX.Element {
    const [secondColor, setSecondColor] = useState(false);
    const [awgStrandsDiameter, setAwgStrandsDiameter] = useState<Array<number>>([...strandDiameterAwg]);
    const {state: appState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    const {
        register,
        getValues,
        watch,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = useForm<CableComponent>({
        defaultValues: wire,
    });

    useEffect(() => {
        if (appState.selectedCompIds.length !== 1) return;
        const selected = findNodeById(appState.selectedCompIds[0], cableState);
        reset(selected);
    }, [appState.selectedCompIds, cableState, reset])

    function nomDiameterFieldDisabled(): boolean {
        return watch('properties.wire.manually_edit_nom_diameter') === false;
    }

    function handleStrandsTotalChange() {
        const { properties } = getValues();
        if (!properties.wire) return;

        if (properties.wire.strands_units === Unit.awg) {
            // set the diameter list according to the selected number of strands
            const diameterList = awgStrandsDiameterMap.get(`${properties.wire.strands_total}`) ?? [];
            setAwgStrandsDiameter(diameterList);

            reset({
                ...getValues(),
                properties: {
                    ...properties,
                    wire: {
                        ...properties.wire,
                        strands_diameter: diameterList[0],
                    }
                }
            })
        }

        handleNomDiameterCalc();
    }

    function handleNomDiameterCalc() {
        const { properties } = getValues();
        if (!properties.wire) return;
        const {strands_units, strands_total, strands_diameter} = properties.wire;

        if (strands_units === Unit.awg) {
            const awgValueMap = awgToMillimeterMap.get(`${strands_total}/${strands_diameter}`);
            const diameterByAwg = awgValueMap ? awgValueMap.awg : 0;

            reset({
                ...getValues(),
                properties: {
                    ...properties,
                    wire: {
                        ...properties.wire,
                        nominal_diameter: decimalPlaces(diameterByAwg, 4),
                        nominal_diameter_units: strands_units,
                    }
                }
            }, { keepDefaultValues: true }) // use keepDefaultValues so form isDirty will work
        }

        else if (strands_units === Unit.mm) {
            const totalStrands = strands_total ? +strands_total : 0;
            const strandValues = strandValuesPerLevel(totalStrands);
            if (!strandValues) return;
            const newNomDiameter = strands_diameter ? +strands_diameter * strandValues.diameterMultiply : 0;

            reset({
                ...getValues(),
                properties: {
                    ...properties,
                    wire: {
                        ...properties.wire,
                        nominal_diameter: decimalPlaces(newNomDiameter, 4),
                        nominal_diameter_units: properties.wire.strands_units,
                    }
                }
            }, { keepDefaultValues: true })
        }
    }

    function handleNomDiameterUnitsChange() {
        const {properties} = getValues();
        if (!properties.wire) return;

        if (properties.wire.nominal_diameter_units === Unit.awg) {
            reset({
                ...getValues(),
                properties: {
                    ...properties,
                    wire: {
                        ...properties.wire,
                        nominal_diameter: 0,
                    }
                }
            }, { keepDefaultValues: true });
        }
    }

    function handleCoordinateChange() {
        const {properties} = getValues();

        reset({
            ...getValues(),
            properties: {
                ...properties,
                manually_edit_coordinates: true
            }
        }, { keepDefaultValues: true });
    }

    async function submitData(wireData: CableComponent) {
        const { colors } = getValues('properties');
        if (colors) {
            if (secondColor && !colors[1]) wireData.properties.colors = [colors[0], '#000000'];
            if (!secondColor && colors[1]) wireData.properties.colors = [colors[0]];
        }

        wireData.nodes = wirePropertiesData(wireData.properties.wire);
        formatCircleData(wireData, cableState, appState);
        dispatchCable({type: 'update component', payload: [wireData]});
    }


    return (
        <form
            onSubmit={handleSubmit(submitData)}
            noValidate={true}
            className={propertiesForm}
        >
            <div className={propertiesContainer}>
                <div>
                    <SectionTitle title={`Strands`}/>
                    <FormRow>
                        <div>
                            <label className={labelCss}>Total</label>
                            <select
                                className={selectCss}
                                {...register('properties.wire.strands_total', {
                                    onChange: handleStrandsTotalChange
                                })}
                            >
                                <SelectOptions
                                    options={numberOfStrands}
                                />
                            </select>
                        </div>
                        <div>
                            <label className={labelCss}>Diameter</label>
                            {
                                watch('properties.wire.strands_units') === Unit.mm ? (
                                    <input
                                        className={inputNumCss}
                                        type="number"
                                        min={0}
                                        step={0.001}
                                        {...register('properties.wire.strands_diameter', {
                                            onChange: handleNomDiameterCalc
                                        })}
                                    />
                                ) : (
                                    <select
                                        className={selectCss}
                                        {...register('properties.wire.strands_diameter', {
                                            onChange: handleNomDiameterCalc
                                        })}
                                    >
                                        <SelectOptions
                                            options={awgStrandsDiameter}
                                        />
                                    </select>
                                )
                            }
                        </div>
                        <div>
                            <label className={labelCss}>Units</label>
                            <select
                                className={selectCss}
                                {...register('properties.wire.strands_units', {
                                    onChange: handleStrandsTotalChange
                                })}
                            >
                                <SelectOptions
                                    options={unitsList}
                                />
                            </select>
                        </div>
                    </FormRow>
                </div>


                <div>
                    <SectionTitle title={`Copper`}/>
                    <FormRow>
                        <div>
                            <label className={labelCss}>Nom. Diameter</label>
                            <input
                                className={inputNumCss}
                                type="number"
                                min={0}
                                step={0.001}
                                disabled={nomDiameterFieldDisabled()}
                                {...register('properties.wire.nominal_diameter')}
                            />
                        </div>
                        <div>
                            <label className={labelCss}>Units</label>
                            <select
                                className={selectCss}
                                disabled={nomDiameterFieldDisabled()}
                                {...register('properties.wire.nominal_diameter_units', {
                                    onChange: () => handleNomDiameterUnitsChange()
                                })}
                            >
                                <SelectOptions
                                    options={unitsList}
                                />
                            </select>
                        </div>
                    </FormRow>
                    <div className={`flex items-center mb-4`}>
                        <input
                            type="checkbox"
                            className={`${checkboxCss}`}
                            {...register('properties.wire.manually_edit_nom_diameter')}
                        />
                        <label>Manually edit values</label>
                    </div>
                    <div>
                        <label className={labelCss}>Copper Color</label>
                        <input
                            className={`${inputColorCss}`}
                            type="color"
                            {...register(`properties.wire.copper_color`)}
                        />
                    </div>
                </div>


                <div>
                    <SectionTitle title={`Insulation Outer Diameter`}/>
                    <FormRow>
                        <div>
                            <div>
                                <input
                                    className={inputNumCss}
                                    type="number"
                                    min={0}
                                    step={0.001}
                                    {...register('properties.wire.outer_insulation_diameter')}
                                />
                                <label className={`pl-4`}>mm</label>
                            </div>
                        </div>
                    </FormRow>
                </div>


                <div>
                    <SectionTitle title={`Insulation Colors`}/>
                    <FormRow>
                        <div>
                            <label className={labelCss}>Color 1</label>
                            <input
                                className={`${inputColorCss}`}
                                type="color"
                                {...register(`properties.colors.${0}`)}
                            />
                        </div>
                        <div>
                            <label className={labelCss}>Color 2</label>
                            <input
                                className={`${inputColorCss}`}
                                type="color"
                                disabled={!secondColor}
                                {...register(`properties.colors.${1}`)}
                            />
                        </div>
                        <div className={`flex items-end pb-2`}>
                            <input
                                type="checkbox"
                                className={`${checkboxCss}`}
                                checked={secondColor}
                                onChange={() => setSecondColor(!secondColor)}
                            />
                            <label>2 colors</label>
                        </div>
                    </FormRow>
                </div>

                <div>
                    <SectionTitle title={`Coordinates`}/>
                    <FormRow>
                        <div>
                            <label className={labelCss}>X</label>
                            <input
                                className={`${inputCss}`}
                                type="number"
                                min={chartCfg.axis.dataRange.start}
                                max={chartCfg.axis.dataRange.end}
                                step={0.1}
                                {...register(`properties.x`, {
                                    onChange: handleCoordinateChange
                                })}
                            />
                        </div>
                        <div>
                            <label className={labelCss}>Y</label>
                            <input
                                className={`${inputCss}`}
                                type="number"
                                min={chartCfg.axis.dataRange.start}
                                max={chartCfg.axis.dataRange.end}
                                step={0.1}
                                {...register(`properties.y`, {
                                    onChange: handleCoordinateChange
                                })}
                            />
                        </div>
                    </FormRow>
                </div>
            </div>

            <div className="p-4 flex items-center justify-center gap-4">
                <button
                    type="submit"
                    className={`${btnPrimaryCss} px-8`}
                    disabled={!isDirty}
                >
                    Draw
                </button>
            </div>
        </form>
    );
}

const btn = `
    cursor-pointer select-none 
    font-medium uppercase leading-6
    disabled:opacity-25 disabled:cursor-default disabled:pointer-events-none
    focus:outline-none focus:ring-0 focus:ring-offset-0
`;

const btnRounded = `${btn} rounded`;

const btnPadding = `
    ${btnRounded} px-4 py-2
`;

const btnFlexBasic = `
    ${btn} inline-flex items-center
`;

const btnFlexRounded = `${btnFlexBasic} rounded`;


// icon button
export const iconBtnFlexCss = `
    ${btnFlexBasic} px-4 rounded bg-pickled-bluewood-100/10 hover:bg-pickled-bluewood-100/20
`;

export const iconBtnDarkCss = `
    ${btn} px-2 rounded hover:bg-picton-blue-500/50
`;

export const iconBtnDarkSquareCss = `
    ${btn} hover:bg-picton-blue-500 
`;

export const activeButton = `bg-picton-blue-500`;


// dark button
const btnDarkColor = `bg-big-stone-500 border-big-stone-500 text-white hover:bg-big-stone-400`;
export const btnDarkSmallCss = `${btnFlexRounded} ${btnDarkColor} px-3 py-1`;


// primary button
const btnPrimaryColor = `
    bg-picton-blue-500 border-picton-blue-500 text-big-stone-900 
    hover:bg-picton-blue-400
`;

export const btnPrimaryCss = `
    ${btnPadding} ${btnPrimaryColor}
`;


// outlined button
const btnOutlineColor = `opacity-70 border border-white`;
export const btnOutlineCss = `
    ${btnPadding} ${btnOutlineColor}
`;

import { Component, createRef } from 'react';
import * as d3 from 'd3';
import { ZoomBehavior, ZoomTransform } from 'd3-zoom';
import { chartCfg } from './CableChartConfig';
import { Selection } from 'd3-selection';
import { Axis } from './chart/Axis';
import { Grid } from './chart/Grid';
import { AppState, CableComponent, ComponentType } from '../shared/types';
import { ScaleLinear } from 'd3-scale';
import { Wire } from './chart/Wire';
import { Foil } from './chart/Foil';
import { Jacket } from './chart/Jacket';
import { Braid } from './chart/Braid';
import { Group } from './chart/Group';
import { calcHullData, isConvex } from '../shared/services/Utils';
import { findNodeById } from '../shared/services/TreeDataManager';
import { TwistedPair } from './chart/Twisted';

interface Props {
    appState: AppState;
    onChange: (state: AppState) => void;
    cableState: Array<CableComponent>;
    onCableChange: (state: Array<CableComponent>) => void;
    onComponentUpdate: (component: CableComponent) => void;
}

const selectChartComponent = (ctrlKey: boolean, componentId: string) => {
    const {active} = chartCfg.cssSelector;
    if (!ctrlKey) d3.selectAll(`.${active}:not(#${componentId})`).classed(active, false);
    d3.select(`#${componentId}`).classed(active, true);
}

export class CableChart extends Component<Props> {
    initialized;
    wrapperRef;
    canvasContainerRef;
    pixelsPerMm: number;
    // @ts-ignore
    svg: Selection<any, any, any, any>;
    // @ts-ignore
    zoomController: ZoomBehavior<any, any>;
    // @ts-ignore
    currentZoom: ZoomTransform;
    // @ts-ignore
    axisX: ScaleLinear<number, number, never>;
    // @ts-ignore
    axisY: ScaleLinear<number, number, never>;

    constructor(props: Props) {
        super(props);

        this.initialized = false;
        this.wrapperRef = createRef<HTMLDivElement>();
        this.canvasContainerRef = createRef<HTMLDivElement>();
        this.pixelsPerMm = 0;
    }

    toggleChartElement = (toggle: boolean, selector: string) => {
        const css = toggle ? selector : `${selector} hidden`;
        d3.selectAll(`.${selector}`).attr('class', css);
    }

    setZoom = () => {
        const selector = chartCfg.zoom.selector;
        const svg = this.svg;

        this.zoomController = d3.zoom()
        .scaleExtent([chartCfg.zoom.min, chartCfg.zoom.max])
        .on('zoom', ({transform}) => {
            // svg.attr('transform', transform);
            svg.selectAll(`.${selector}`).attr('transform', transform);
            this.currentZoom = transform;
        })

        this.svg
        .call(this.zoomController)
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        // .on('mousedown.zoom', null) // prevent dragging the zoom area
    }

    zoom = (step: number) => {
        this.svg.transition().call(this.zoomController.scaleBy, step);
    }

    handleDragEnd = (component: CableComponent, translation: [number, number]) => {
        let properties = {...component.properties};
        if (!isConvex(component)) {
            const x = (translation[0] - this.axisX(0)) / this.pixelsPerMm;
            const y = (this.axisY(0) - translation[1]) / this.pixelsPerMm;
            properties = {
                ...properties,
                x,
                y,
            }
        }

        this.props.onComponentUpdate({
            ...component,
            chartData: {
                ...component.chartData,
                compAbsolutePos: translation,
            },
            properties,
        });
    }

    handleWireSelection = (event: any, selectedId: string, parentId?: string) => {
        if (parentId) {
            // cannot select an individual component inside a group, must select the entire group
            const parent = findNodeById(parentId, this.props.cableState);
            if (parent) return;
        }

        selectChartComponent(event.ctrlKey, selectedId);

        let selectedCompIds: Array<string> = [selectedId];

        if (event.ctrlKey) { // multi selection
            selectedCompIds = [
                ...this.props.appState.selectedCompIds,
                selectedId
            ]
        }

        this.props.onChange({
            ...this.props.appState,
            selectedCompIds,
        });
    }

    updateWire = (
        component: CableComponent,
        container: Selection<SVGSVGElement, CableComponent, any, any>,
    ) => {
        Wire({
            container,
            component,
            pixelsPerMm: this.pixelsPerMm,
            tree: this.props.cableState,
        })
    }

    updateFoil = (
        component: CableComponent,
        container: Selection<SVGSVGElement, CableComponent, any, any>
    ) => {
        let comp = {...component};
        let compThick = component.properties.thickness ? +component.properties.thickness : 0;
        const child = component.children[0];
        if (isConvex(child)) {
            const offset = 0.1;
            comp = {...child}
            compThick = child.properties.thickness ? +child.properties.thickness + compThick : compThick;
            compThick += offset;
        }

        component.chartData.hullData = calcHullData(
            comp,
            this.pixelsPerMm,
            this.props.appState.axisCenter,
            this.props.cableState,
            compThick,
        );

        Foil({
            container,
            component,
            pixelsPerMm: this.pixelsPerMm,
        })
    }

    updateTwistedPair = (
        component: CableComponent,
        container: Selection<SVGSVGElement, CableComponent, any, any>
    ) => {
        let comp = {...component};
        let compThick = component.properties.thickness ? +component.properties.thickness : 0;
        const child = component.children[0];
        if (isConvex(child)) {
            comp = {...child}
            compThick = child.properties.thickness ? +child.properties.thickness + compThick : compThick;
        }

        component.chartData.hullData = calcHullData(
            comp,
            this.pixelsPerMm,
            this.props.appState.axisCenter,
            this.props.cableState,
            compThick,
        );

        TwistedPair({
            container,
            component,
            thickness: compThick * this.pixelsPerMm
        })
    }

    updateJacket = (
        component: CableComponent,
        container: Selection<SVGSVGElement, CableComponent, any, any>
    ) => {
        Jacket({
            container,
            component,
            pixelsPerMm: this.pixelsPerMm,
            tree: this.props.cableState,
        })
    }

    updateBraid = (
        component: CableComponent,
        container: Selection<SVGSVGElement, CableComponent, any, any>
    ) => {
        Braid({
            container,
            component,
            pixelsPerMm: this.pixelsPerMm,
            tree: this.props.cableState,
        })
    }

    drawComponentByType = (
        component: CableComponent,
        group: Selection<SVGSVGElement, CableComponent, any, any>,
    ) => {
        switch (component.type) {
            case ComponentType.WIRE:
                this.updateWire(component, group);
                break;
            case ComponentType.FOIL:
                this.updateFoil(component, group);
                break;
            case ComponentType.TWISTED_PAIR:
                this.updateTwistedPair(component, group);
                break;
            case ComponentType.JACKET:
                this.updateJacket(component, group);
                break;
            case ComponentType.BRAID:
                this.updateBraid(component, group);
                break;
        }
    }

    drawGroupItems = (component: CableComponent, group: Selection<SVGSVGElement, CableComponent, any, any>) => {
        component.children.forEach(child => {
            this.drawComponentByType(child, group);
            if (child.children) this.drawGroupItems(child, group);
        })
    }

    getGroupPos = (component: CableComponent): [number, number] => {
        const { compAbsolutePos } = component.chartData;

        if (isConvex(component)) {
            return compAbsolutePos;
        }

        const x = compAbsolutePos[0] === 0 ? this.axisX(0) : compAbsolutePos[0];
        const y = compAbsolutePos[1] === 0 ? this.axisY(0) : compAbsolutePos[1];
        return [x, y];
    }

    componentDidUpdate({appState: prevAppState, cableState: prevCable}: Props) {
        const { showAxes, showGrid, zoom } = this.props.appState;

        if (showAxes !== prevAppState.showAxes) {
            this.toggleChartElement(showAxes, chartCfg.axis.selector);
        }

        if (showGrid !== prevAppState.showGrid) {
            this.toggleChartElement(showGrid, chartCfg.cssSelector.grid);
        }

        if (zoom !== prevAppState.zoom) {
            if (zoom > 0) this.zoom(chartCfg.zoom.stepUp);
            if (zoom < 0) this.zoom(chartCfg.zoom.stepDown);
        }

        if (JSON.stringify(this.props.cableState) !== JSON.stringify(prevCable)) {
            // console.log(this.props.cableState)

            d3.selectAll(`.${chartCfg.cssSelector.group}`).remove();

            const { selectedCompIds } = this.props.appState;
            this.props.cableState.forEach(component => {
                const isActive = selectedCompIds.includes(component.id);
                const group = Group({
                    svg: this.svg,
                    component,
                    position: this.getGroupPos(component),
                    isActive,
                    onDragEnd: this.handleDragEnd,
                    onSelect: this.handleWireSelection,
                });

                this.drawComponentByType(component, group);
                this.drawGroupItems(component, group);
            })
        }
    }

    componentDidMount() {
        if (!this.canvasContainerRef.current || !this.wrapperRef.current || this.initialized) return;
        this.initialized = true;

        const {margin, axis: {dataRange}} = chartCfg;
        const wrapper = this.wrapperRef.current;
        const divContainer = this.canvasContainerRef.current;
        const maxBoardSize = Math.min(wrapper.offsetWidth - (margin * 2), wrapper.offsetHeight - (margin * 2));
        divContainer.style.height = `${maxBoardSize}px`;
        divContainer.style.width = `${maxBoardSize}px`;
        this.pixelsPerMm = (maxBoardSize - margin) / (dataRange.end - dataRange.start);

        this.svg =
            d3
            .select(divContainer)
            .append('svg')
            .attr('id', chartCfg.selector)
            .attr('version', `1.1`)
            .attr('viewBox', [0, 0, maxBoardSize, maxBoardSize])
            .attr('width', `100%`)
            .attr('height', `100%`)

        const axisX = Axis({
            svgEl: this.svg,
            boardSize: maxBoardSize,
            axisType: 'x'
        })

        const axisY = Axis({
            svgEl: this.svg,
            boardSize: maxBoardSize,
            axisType: 'y'
        })

        this.axisX = axisX;
        this.axisY = axisY;

        this.props.onChange({
            ...this.props.appState,
            pixelsPerMm: this.pixelsPerMm,
            axisCenter: [axisX(0), axisY(0)],
            axisX: axisX,
            axisY: axisY,
        });

        Grid({
            svgEl: this.svg,
            boardSize: maxBoardSize,
            axisX,
            axisY,
        })


        // zoom
        // todo: if in zoom, on add:
        /*
        if (this.currentZoom) {
                this.docSvg.select(`#${markup.id}`).attr('transform', this.currentZoom);
            }
         */

        this.setZoom()
    }

    render(): JSX.Element {
        return (
            <div className={`flex-1 flex items-center justify-center`} ref={this.wrapperRef}>
                <div ref={this.canvasContainerRef} />
            </div>
        )
    }
}

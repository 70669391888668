import { AppState, CableComponent, ComponentType, StrandLevel, Unit, WireProperties } from '../types';
import { awgToMillimeterMap } from '../../properties/LookupTable';
import { wirePropertiesData } from '../../properties/forms/WireForm';
import { findNodeById } from './TreeDataManager';

export function handleException(err: string) {
    if (process.env.NODE_ENV === 'production') {
        return;
    }
    console.error(err);
    throw new Error(err);
}

export function generateKey(): number {
    return Math.trunc(Math.random() * 100000);
}

export function isConvex({ type }: CableComponent): boolean {
    return (type === ComponentType.FOIL || type === ComponentType.TWISTED_PAIR);
}

export const strandValuesPerLevel = (totalStrands: number): StrandLevel | undefined => {
    // diameterMultiply = 2*level -1;

    if (totalStrands === 1) {
        return {level: 1, diameterMultiply: 1, strandsCount: {from: 1, to: 1}};
    }
    else if (totalStrands >= 2 && totalStrands <=7) {
        return {level: 2, diameterMultiply: 3, strandsCount: {from: 2, to: 7}};
    }
    else if (totalStrands >= 8 && totalStrands <=19) {
        return {level: 3, diameterMultiply: 5, strandsCount: {from: 8, to: 19}};
    }
    else if (totalStrands >= 20 && totalStrands <=37) {
        return {level: 4, diameterMultiply: 7, strandsCount: {from: 20, to: 37}};
    }
    else if (totalStrands >= 38 && totalStrands <=42) {
        return {level: 5, diameterMultiply: 9, strandsCount: {from: 38, to: 42}};
    }
    else {
        handleException(`incorrect number of strands`);
    }
}

function diameterInMm(diameter: number, units?: Unit, strandsTotal?: number): number {
    if (units === Unit.awg) {
        if (!strandsTotal) return 0;
        const awgToMm = awgToMillimeterMap.get(`${strandsTotal}/${diameter}`);
        return awgToMm ? awgToMm.mm : 0;
    }

    return diameter;
}

export function getStrandDiameter(wire?: Partial<WireProperties>): number {
    if (!wire) return 0;
    const {strands_units, strands_total, strands_diameter} = wire;

    if (wire.manually_edit_nom_diameter && wire.nominal_diameter && wire.strands_total) {
        const values = strandValuesPerLevel(+wire.strands_total);
        if (!values) return 0;
        const diameter = +wire.nominal_diameter / values.diameterMultiply;
        return diameterInMm(diameter, wire.nominal_diameter_units, wire.strands_total);
    }

    if (strands_units === Unit.awg) {
        const awgValueMap = awgToMillimeterMap.get(`${strands_total}/${strands_diameter}`);
        return awgValueMap ? awgValueMap.mm : 0;
    }

    return strands_diameter ? +strands_diameter : 0;
}

export function circleCoordinatesPx(
    component: CableComponent,
    tree: Array<CableComponent>,
    totalPos: [number, number],
): [number, number] {
    let dx=totalPos[0], dy=totalPos[1];
    const { compAbsolutePos, groupRelativePos } = component.chartData;

    if (component.parentId) {
        if (!isConvex(component) && groupRelativePos) {
            dx += groupRelativePos[0];
            dy += groupRelativePos[1];
        }

        const parent = findNodeById(component.parentId, tree);
        if (!parent) {
            return [dx, dy];
        }

        return circleCoordinatesPx(parent, tree, [dx, dy]);
    }

    else {
        if (!isConvex(component)) {
            dx += compAbsolutePos[0];
            dy += compAbsolutePos[1];
        }
        return [dx, dy];
    }
}

export function circlePosition(
    component: CableComponent,
    nodePos: [number, number], // starts with the absolute pos
    tree: Array<CableComponent>,
): [number, number] {
    // --- circle on top level (without parent)

    if (!component.parentId) {
        return nodePos;
    }

    const parent = findNodeById(component.parentId, tree);
    if (!parent) return nodePos;

    // --- has parent

    const { compAbsolutePos, groupRelativePos } = component.chartData;
    let dx=nodePos[0], dy=nodePos[1];
    if (isConvex(parent)) { // parent of type path
        dx += compAbsolutePos[0];
        dy += compAbsolutePos[1];
    }
    else if (groupRelativePos) { // parent of type circle
        dx += groupRelativePos[0];
        dy += groupRelativePos[1];
    }

    return circlePosition(parent, [dx, dy], tree);
}

function pointsOnPerimeter(diameter: number, thickness: number): Array<Array<number>> {
    const degToRad = Math.PI / 180;
    const spaceBetweenPoints = 5; // for better performance and less smooth - increase number.
    const radius = (diameter / 2) + thickness; // to take it further away from the perimeter - increase thickness.
    let result = [];

    for (let angle=0 ; angle < 360 ; angle += spaceBetweenPoints) {
        const radAngle = angle * degToRad;
        const perimeterX = Math.cos(radAngle) * radius;
        const perimeterY = Math.sin(radAngle) * radius;

        result.push([
            perimeterX,
            perimeterY,
        ])
    }

    return result;
}

export function calcHullData(
    wrapperComponent: CableComponent,
    pixelsPerMm: number,
    axisCenterPos: [number, number],
    tree: Array<CableComponent>,
    thickness: number,
): Array<[number, number]> {
    const hullData: Array<[number, number]> = [];

    for (const childComponent of wrapperComponent.children) {
        let diameter, data;
        if (childComponent.type === ComponentType.WIRE) {
            const { wire } = childComponent.properties;
            if (!wire) { continue; }
            if (wire.outer_insulation_diameter) {
                diameter = +wire.outer_insulation_diameter;
                data = [{radius: diameter/2, x:0, y: 0}];
            }
            else {
                diameter = getStrandDiameter(wire);
                data = wirePropertiesData(wire);
            }
        }

        // todo: foil on braid/jacket
        // else if (
        //     childComponent.type === ComponentType.BRAID ||
        //     childComponent.type === ComponentType.JACKET) {
        // const mainPoint = childComponent.nodes[0];
        //     diameter = mainPoint.radius * 2;
        //
        //     // console.log(d3.select(`#${childComponent.id}`).data())
        //
        //
        //     data = [{
        //         radius: mainPoint.radius,
        //         x: 0, // childComponent.chartData.compAbsolutePos[0] - axisCenterPos[0],
        //         y: 0, // childComponent.chartData.compAbsolutePos[1] - axisCenterPos[1],
        //     }];
        // }
        if (!diameter || !data) { continue; }

        const points = pointsOnPerimeter(diameter, thickness);
        const centerPoint = childComponent.nodes[0];
        const nodePos: [number, number] = [centerPoint.x * pixelsPerMm, centerPoint.y * pixelsPerMm];
        const position = circlePosition(childComponent, nodePos, tree);

        data.forEach(strand => { // to improve performance: take only circles from 2 outer levels
            points.forEach(point => {
                hullData.push([
                    ( (strand.x + point[0]) * pixelsPerMm ) + position[0],
                    ( (strand.y + point[1]) * pixelsPerMm ) + position[1],
                ]);
            })
        })
    }

    return hullData;
}


export function formatCircleData(component: CableComponent, cable: Array<CableComponent>, appData: AppState){
    if (!appData.axisX || !appData.axisY) return;
    const { manually_edit_coordinates, x, y } = component.properties;

    if (!manually_edit_coordinates ||
        typeof x === 'undefined' ||
        typeof y === 'undefined')
    {
        return;
    }
    const propX = appData.axisX(+x);
    const propY = appData.axisY(+y);
    component.chartData.compAbsolutePos = [propX, propY];
}

import { useApp } from '../../app/AppContext';
import { forwardRef, MouseEvent, useEffect, useRef, useState } from 'react';
import { CableComponent } from '../shared/types';
import { SimpleTreeItemWrapper, TreeItem, TreeItemComponentProps } from 'dnd-kit-sortable-tree';
import { MdCheck, MdClose, MdEdit } from 'react-icons/md';
import { btnDarkSmallCss } from '../../theme/css/button';
import { inputSmCss } from '../../theme/css/form';
import { useCable } from '../cable/CableContext';
import { toast } from 'react-toastify';

export interface TreeNode extends TreeItem<CableComponent> {}

const selectedCss = `bg-wild-watermelon-500 px-2 rounded`;

export const PropertiesTreeItem = forwardRef<
    HTMLDivElement,
    TreeItemComponentProps<TreeNode>
    >((props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { /*childCount,*/ clone, onRemove, item, isOver, isOverParent, parent } = props;
    const [showInput, setShowInput] = useState(false);
    const [name, setName] = useState(item.value);
    const {state: appState, dispatch: dispatchApp} = useApp();
    const {dispatch: dispatchCable} = useCable();

    useEffect(() => {
        if (!showInput || !inputRef.current) return;
        inputRef.current.focus();
    }, [showInput, inputRef])

    function css(): string {
        let css = '';
        if (isOver) css += '{bg-nile-blue-400 bg-opacity-20 ';
        if (isOverParent) css += 'bg-nile-blue-400 bg-opacity-50 ';
        return css;
    }

    function handleLabelChange(e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        if (!name) {
            toast.error(`Invalid name`);
            return;
        }
        setShowInput(false);
        dispatchCable({type: 'update component', payload: [{
            ...item,
            value: name
        }]});
    }

    function handleShowEdit(e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        setShowInput(true);
    }

    function handleDelete(e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        dispatchApp({ type: 'set config', payload: {selectedCompIds: []} })
        if (onRemove) onRemove();
    }

    function handleItemClick() {
        // cannot change properties of a child inside a group. can only change the parent node
        const selectedCompIds = (parent) ? [] : [item.id];
        dispatchApp({type: 'set config', payload: {selectedCompIds}});
    }


    return (
        <SimpleTreeItemWrapper
            {...props}
            contentClassName={css()}
            ref={ref}
        >
            <div className={`flex justify-between flex-1 items-center`}>
                {
                    !showInput &&
                    <span
                        className={`truncate ${appState.selectedCompIds.includes(props.item.id) ? selectedCss : 'px-2'}`}
                        onClick={handleItemClick}
                    >
                        {item.value}
                    </span>
                }
                {
                    showInput &&
                    <input
                        className={inputSmCss}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        ref={inputRef}
                        minLength={1}
                    />
                }
                <div className={`flex gap-2`}>
                    {
                        !showInput &&
                        <button
                            className={btnDarkSmallCss}
                            onClick={handleShowEdit}
                        >
                            <MdEdit />
                        </button>
                    }
                    {
                        showInput &&
                        <button
                            className={btnDarkSmallCss}
                            onClick={handleLabelChange}
                        >
                            <MdCheck />
                        </button>
                    }
                    {!clone && onRemove && item.depth === 0 && (
                        <button
                            className={btnDarkSmallCss}
                            onClick={handleDelete}
                        >
                            <MdClose />
                        </button>
                    )}
                </div>
            </div>
        </SimpleTreeItemWrapper>
    );
});

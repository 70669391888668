import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement>{
    title: string
}

const defaultCss = 'text-xl py-1 px-3 bg-[#2e4965cf]';

export function PanelTitle({title, className=defaultCss, ...rest}: Props) {
    return (
        <div className={className} {...rest}>
            {title}
        </div>
    )
}

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useMemo, useState } from 'react';
import { borderBottomCss } from '../../theme/css/common';
import { PropertiesTree } from './PropertiesTree';
import { PanelTitle } from '../shared/components/PanelTitle';
import { useApp } from '../../app/AppContext';
import { CableComponent, ComponentType } from '../shared/types';
import { WireForm } from './forms/WireForm';
import { useCable } from '../cable/CableContext';
import { FoilForm } from './forms/FoilForm';
import { JacketForm } from './forms/JacketForm';
import { BraidForm } from './forms/BraidForm';
import { findNodeById } from '../shared/services/TreeDataManager';

const width = {min: 2, max: 25};

function NoSelection(): JSX.Element {
    return (
        <div className={`h-40 flex items-center justify-center`}>
            <span className={`font-bold uppercase text-lg text-white/20 text-center p-4`}>
                Select or add a component to view its properties
            </span>
        </div>
    )
}

function ComponentProperties({selectedId}: {selectedId: string}): JSX.Element {
    const {state: cableState} = useCable();
    const selected = findNodeById(selectedId, cableState);
    if (!selected) return <></>;

    switch (selected.type) {
        case ComponentType.WIRE:
            return <WireForm wire={selected as CableComponent} />;
        case ComponentType.FOIL:
            return <FoilForm foil={selected as CableComponent} />;
        case ComponentType.JACKET:
            return <JacketForm jacket={selected as CableComponent} />;
        case ComponentType.BRAID:
            return <BraidForm braid={selected as CableComponent} />;
        default:
            return <></>
    }
}

export function PropertiesPanel(): JSX.Element {
    const [panelWidth, setPanelWidth] = useState(width.max);
    const {state: appState} = useApp();

    const isCollapsed = useMemo((): boolean => {
        return panelWidth === width.min;
    }, [panelWidth]);

    function handleToggleWidth() {
        if (panelWidth === width.min) {
            setPanelWidth(width.max);
        }
        else {
            setPanelWidth(width.min);
        }
    }


    return (
        <div className={`bg-fs-dark-800 max-w-[30%] flex flex-col`} style={{width: `${panelWidth}rem`}}>
            <div className={`flex ${borderBottomCss}`}>
                <button
                    onClick={handleToggleWidth}
                    className={`border-0 bg-transparent p-2 hover:bg-pickled-bluewood-100/20 w-[32px]`}
                >
                    {!isCollapsed && <MdKeyboardArrowRight/>}
                    {isCollapsed && <MdKeyboardArrowLeft/>}
                </button>
                <PanelTitle title={`Layout`} style={{flex: '1 1', display: (panelWidth === width.min ? 'none' : 'block')}}/>
            </div>

            <div className={`flex-1 flex flex-col ${isCollapsed ? 'hidden' : 'block'}`} style={{flex: '1 1 auto'}}>
                <div className={`overflow-y-auto h-[200px]`}>
                    <PropertiesTree />
                </div>
                <div className={`flex-1 flex flex-col`}>
                    <PanelTitle title={`Properties`}/>
                    <>
                        {
                            (appState.selectedCompIds.length === 1) ?
                                <ComponentProperties selectedId={appState.selectedCompIds[0]} /> :
                                (<NoSelection />)
                        }
                    </>
                </div>
            </div>
        </div>
    )
}

import { Selection } from 'd3-selection';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';
import { circlePosition } from '../../shared/services/Utils';

interface Props {
    container: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    pixelsPerMm: number;
    tree: Array<CableComponent>;
}

export function Braid({
    container,
    component,
    pixelsPerMm,
    tree,
}: Props) {
    const {properties, nodes} = component;
    if (nodes.length === 0) return;
    const {colors, thickness} = properties;
    const {radius, x, y} = nodes[0];
    const color = colors ? colors[0] : '';
    const strokeWidth = thickness ? +thickness * pixelsPerMm : 0;
    const nodePos: [number, number] = [x * pixelsPerMm, y * pixelsPerMm];
    const position = circlePosition(component, nodePos, tree);

    const patternPath = ['M 5,5 L 10,10', 'M 5,5 L 0,10', 'M 5,5 L 10,0', 'M 5,5 L 0,0'];
    const pattern =
        container
        .append('defs')
        .append('pattern')
        .attr('id', 'braidPattern')
        .attr('width', 10)
        .attr('height', 10)
        .attr('patternUnits', 'userSpaceOnUse');
    patternPath.forEach(path => {
        pattern
        .append('path')
        .attr('stroke', color)
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 2)
        .attr('d', path)
    })

    container
    .append('circle')
    .attr('cx', position[0])
    .attr('cy', position[1])
    .attr('r', pixelsPerMm * radius)
    .attr('class', `${chartCfg.cssSelector.braid}`)
    .attr('fill', 'none')
    .attr('stroke', 'url(#braidPattern)')
    .attr('stroke-width', strokeWidth)
    .attr('stroke-location', 'inside')
    .attr('stroke-linejoin', 'round');
}



import React from 'react';
import { TopBar } from '../modules/bars/top/TopBar';
import { ComponentsBar } from '../modules/bars/components/ComponentsBar';
import { PropertiesPanel } from '../modules/properties/PropertiesPanel';
import { MainPanel } from '../modules/cable/MainPanel';
import { AppProvider } from './AppContext';
import { CableProvider } from '../modules/cable/CableContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

if (process.env.NODE_ENV !== 'production') {
    console.log(`You are running this application in ${process.env.NODE_ENV} mode.`);
}

function App() {
    return (
        <>
            <AppProvider>
                <CableProvider>
                    <div className={`h-full flex flex-col`}>
                        <TopBar />

                        <div className={`flex-1 bg-big-stone-500 flex items-stretch`}>
                            <ComponentsBar />
                            <MainPanel />
                            <PropertiesPanel />
                        </div>
                    </div>
                </CableProvider>
            </AppProvider>

            <ToastContainer
                position="top-center"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnHover
                theme="dark"
            />
        </>
    );
}

export default App;

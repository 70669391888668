import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';

interface Props {
    container: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    thickness: number;
}

export function TwistedPair({
    container,
    component,
    thickness=2,
}: Props) {
    if (!component.chartData.hullData) return;
    const hull = d3.polygonHull(component.chartData.hullData);
    if (!hull) return;

    const path = 'M' + hull.join('L') + 'Z';

    container
    .append('path')
    .attr('d', path)
    .attr('class', `${chartCfg.cssSelector.twisted}`)
    .attr('fill', 'none')
    .attr('stroke', '#000000')
    .attr('stroke-width', thickness)
    .attr('stroke-linecap', 'butt')
    .attr('stroke-dasharray', '4,2')
    .attr('stroke-dashoffset', 0)
    .attr('stroke-linejoin', 'round');
}

import { createContext, ReactNode, useContext, useReducer } from 'react';
import { CableComponent } from '../shared/types';
import { deleteNodes, updateNode } from '../shared/services/TreeDataManager';

type Action = {
    type: 'add component' | 'update component' | 'delete component' | 'update cable',
    payload: Array<CableComponent>,
}

type Dispatch = (action: Action) => void
type Context = { state: Array<CableComponent>; dispatch: Dispatch } | undefined
type ProviderProps = { children: ReactNode }

const CableContext = createContext<Context>(undefined);

function cableReducer(currentState: Array<CableComponent>, action: Action): Array<CableComponent> {
    if (action.payload.length === 0) return [];
    const node = action.payload[0];

    switch (action.type) {
        case 'add component':
            return [
                ...currentState,
                ...action.payload,
            ]
        case 'update component':
            return updateNode(node, currentState);
        case 'delete component':
            return deleteNodes([node.id], currentState);
        case 'update cable':
            return action.payload;
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function CableProvider({children}: ProviderProps) {
    const initialState: Array<CableComponent> = [];
    const [state, dispatch] = useReducer(cableReducer, initialState);
    const value = {state, dispatch};

    return (
        <CableContext.Provider value={value}>
            {children}
        </CableContext.Provider>
    )
}

function useCable() {
    const context = useContext(CableContext);
    if (context === undefined) {
        throw new Error('useCable must be used within an CableProvider');
    }

    return context;
}

export { CableProvider, useCable }

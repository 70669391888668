import { AwgMmUnitMap, CableComponent, ComponentType, Unit, } from '../shared/types';

export const strandDiameterAwg = [39, 38, 36, 34, 32, 30, 29, 28, 26, 24, 22, 20, 18, 16, 14, 6];
export const numberOfStrands = [1, 7, 16, 19, 26, 40, 42];
export const unitsList = Object.keys(Unit);

export const awgStrandsDiameterMap: Map<string, Array<number>> = new Map([
    ['1', [39, 38, 36, 34, 32, 30, 29, 28, 26, 24, 22, 20, 18, 16, 14, 6]],
    ['7', [38, 36, 34, 32, 30, 28, 26, 24, 22, 16, 14]],
    ['16', [36, 34, 30, 28]],
    ['19', [38, 36, 32, 29, 16]],
    ['26', [36, 34, 30, 28]],
    ['40', [32]],
    ['42', [32]],
])

export const awgToMillimeterMap: Map<string, AwgMmUnitMap> = new Map([
    ['1/39', {mm: 0.0889, awg: 39}],
    ['1/38', {mm: 0.102, awg: 38}],
    ['1/36', {mm: 0.127, awg: 36}],
    ['1/34', {mm: 0.160, awg: 34}],
    ['1/32', {mm: 0.203, awg: 32}],
    ['1/30', {mm: 0.254, awg: 30}],
    ['1/29', {mm: 0.287, awg: 29}],
    ['1/28', {mm: 0.320, awg: 28}],
    ['1/26', {mm: 0.404, awg: 26}],
    ['1/24', {mm: 0.511, awg: 24}],
    ['1/22', {mm: 0.643, awg: 22}],
    ['1/20', {mm: 0.836, awg: 20}],
    ['1/18', {mm: 1.02, awg: 18}],
    ['1/16', {mm: 1.29, awg: 16}],
    ['1/14', {mm: 1.63, awg: 14}],
    ['1/6', {mm: 4.11, awg: 6}],

    ['7/38', {mm: 0.305, awg: 30}],
    ['7/36', {mm: 0.381, awg: 28}],
    ['7/34', {mm: 0.480, awg: 26}],
    ['7/32', {mm: 0.610, awg: 24}],
    ['7/30', {mm: 0.762, awg: 22}],
    ['7/28', {mm: 0.960, awg: 20}],
    ['7/26', {mm: 1.21, awg: 18}],
    ['7/24', {mm: 1.53, awg: 16}],
    ['7/22', {mm: 1.93, awg: 14}],
    ['7/16', {mm: 3.87, awg: 8}],
    ['7/14', {mm: 4.88, awg: 6}],

    ['16/36', {mm: 0.584, awg: 24}],
    ['16/34', {mm: 0.739, awg: 22}],
    ['16/30', {mm: 1.17, awg: 18}],
    ['16/28', {mm: 1.48, awg: 16}],

    ['19/38', {mm: 0.508, awg: 26}],
    ['19/36', {mm: 0.635, awg: 24}],
    ['19/32', {mm: 1.016, awg: 20}],
    ['19/29', {mm: 1.44, awg: 16}],
    ['19/16', {mm: 6.45, awg: 3}],

    ['26/36', {mm: 0.747, awg: 22}],
    ['26/34', {mm: 0.942, awg: 20}],
    ['26/30', {mm: 1.50, awg: 16}],
    ['26/28', {mm: 1.88, awg: 14}],

    ['40/32', {mm: 1.48, awg: 16}],

    ['42/32', {mm: 1.67, awg: 16}],
]);

export const defaultWireProperties: CableComponent = {
    id: '',
    value: '',
    type: ComponentType.WIRE,
    properties: {
        colors: ['#ffffff'],
        wire: {
            strands_total: 1,
            strands_diameter: 1,
            strands_units: Unit.mm,
            nominal_diameter: 1.00,
            nominal_diameter_units: Unit.mm,
            manually_edit_nom_diameter: false,
            outer_insulation_diameter: 0,
            copper_color: '#d07f39',
        },
        x: 0,
        y: 0,
        manually_edit_coordinates: false,
    },
    nodes: [
        {
            x: 0,
            y: 0,
            radius: 0.5
        }
    ],
    chartData: {
        compAbsolutePos: [0, 0],
    },
    depth: 0,
    children: [],
}

export const defaultFoilProperties: CableComponent = {
    id: '',
    value: '',
    type: ComponentType.FOIL,
    properties: {
        colors: ['#808080'],
        thickness: 0.05,
    },
    nodes: [],
    chartData: {
        compAbsolutePos: [0, 0],
        hullData: [],
    },
    depth: 0,
    children: [],
}

export const defaultTwistedProperties: CableComponent = {
    id: '',
    value: '',
    type: ComponentType.TWISTED_PAIR,
    properties: {
        thickness: 0.08,
    },
    nodes: [],
    chartData: {
        compAbsolutePos: [0, 0],
        hullData: [],
    },
    depth: 0,
    children: [],
}

export const defaultJacketProperties: CableComponent = {
    id: '',
    value: '',
    type: ComponentType.JACKET,
    properties: {
        colors: ['#000000'],
        thickness: 0.1,
        x: 0,
        y: 0,
    },
    nodes: [
        {
            x: 0,
            y: 0,
            radius: 3
        }
    ],
    chartData: {
        compAbsolutePos: [0, 0],
    },
    depth: 0,
    children: [],
}

export const defaultBraidProperties: CableComponent = {
    id: '',
    value: '',
    type: ComponentType.BRAID,
    properties: {
        colors: ['#ffffff'],
        thickness: 0.15,
        x: 0,
        y: 0,
    },
    nodes: [
        {
            x: 0,
            y: 0,
            radius: 2.8
        }
    ],
    chartData: {
        compAbsolutePos: [0, 0],
    },
    depth: 0,
    children: [],
}

import { useForm } from 'react-hook-form';
import { inputCss, labelCss } from '../../../theme/css/form';
import { btnOutlineCss, btnPrimaryCss } from '../../../theme/css/button';

interface Props {
    onSubmit: (data: ImportFormData) => void
    onClose: () => void
}

export interface ImportFormData {
    json: string;
}

export function ImportForm({onSubmit, onClose}: Props): JSX.Element {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ImportFormData>({
        defaultValues: {},
    });

    async function submitData(formData: ImportFormData) {
        onSubmit(formData);
    }


    return (
        <form
            onSubmit={handleSubmit(submitData)}
        >
            <div className={`flex flex-col gap-4 p-6`}>
                <div>
                    <label className={labelCss}>Paste JSON here</label>
                    <div>
                        <textarea
                            rows={8}
                            cols={50}
                            className={inputCss}
                            {...register('json', {
                                required: {value: true, message: 'This field is required'},
                            })}
                        />
                    </div>
                    {
                        errors.json?.message &&
                        <div className={`text-red-600`}>{errors.json?.message}</div>
                    }
                </div>

                <div className="col-span-2 flex h-24 items-center justify-center space-x-4 flex-shrink-0">
                    <button
                        type="submit"
                        className={btnPrimaryCss}
                    >
                        Draw
                    </button>
                    <button
                        type="button"
                        className={btnOutlineCss}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}

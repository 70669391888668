import { CableComponent } from '../../shared/types';
import { useApp } from '../../../app/AppContext';
import { useCable } from '../../cable/CableContext';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { inputColorCss, inputCss, labelCss, propertiesContainer, propertiesForm } from '../../../theme/css/form';
import { btnPrimaryCss } from '../../../theme/css/button';
import { findNodeById } from '../../shared/services/TreeDataManager';
import { chartCfg } from '../../cable/CableChartConfig';
import { formatCircleData } from '../../shared/services/Utils';

export function BraidForm({braid}: {braid: CableComponent}): JSX.Element {
    const {state: appState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    const {
        register,
        getValues,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = useForm<CableComponent>({
        defaultValues: braid,
    });

    useEffect(() => {
        const selected = findNodeById(appState.selectedCompIds[0], cableState);
        reset(selected);
    }, [appState.selectedCompIds, cableState, reset])

    function handleCoordinateChange() {
        const {properties} = getValues();

        reset({
            ...getValues(),
            properties: {
                ...properties,
                manually_edit_coordinates: true
            }
        }, { keepDefaultValues: true });
    }

    async function submitData(braidData: CableComponent) {
        formatCircleData(braidData, cableState, appState);
        dispatchCable({type: 'update component', payload: [braidData]});
    }


    return (
        <form
            onSubmit={handleSubmit(submitData)}
            noValidate={true}
            className={propertiesForm}
        >
            <div className={propertiesContainer}>
                <div>
                    <label className={labelCss}>Thickness</label>
                    <div>
                        <input
                            className={inputCss}
                            type="number"
                            min={0}
                            step={0.001}
                            {...register('properties.thickness')}
                        />
                        <label className={`pl-4`}>mm</label>
                    </div>
                </div>

                <div>
                    <label className={labelCss}>Diameter</label>
                    <div>
                        <input
                            className={inputCss}
                            type="number"
                            min={0}
                            step={0.001}
                            {...register(`nodes.${0}.radius`)}
                        />
                        <label className={`pl-4`}>mm</label>
                    </div>
                </div>

                <div>
                    <label className={labelCss}>Color</label>
                    <input
                        className={`${inputColorCss}`}
                        type="color"
                        {...register(`properties.colors.${0}`)}
                    />
                </div>

                <div className={`flex gap-4 items-center`}>
                    <div>
                        <label className={labelCss}>X</label>
                        <input
                            className={`${inputCss}`}
                            type="number"
                            min={chartCfg.axis.dataRange.start}
                            max={chartCfg.axis.dataRange.end}
                            step={0.1}
                            {...register(`properties.x`, {
                                onChange: handleCoordinateChange
                            })}
                        />
                    </div>
                    <div>
                        <label className={labelCss}>Y</label>
                        <input
                            className={`${inputCss}`}
                            type="number"
                            min={chartCfg.axis.dataRange.start}
                            max={chartCfg.axis.dataRange.end}
                            step={0.1}
                            {...register(`properties.y`, {
                                onChange: handleCoordinateChange
                            })}
                        />
                    </div>
                </div>

            </div>

            <div className="p-4 flex items-center justify-center gap-4">
                <button
                    type="submit"
                    className={`${btnPrimaryCss} px-8`}
                    disabled={!isDirty}
                >
                    Draw
                </button>
            </div>
        </form>
    );
}

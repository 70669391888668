import { iconBtnDarkSquareCss } from '../../../theme/css/button';
import Logo from '../../../theme/images/logo.svg';
import { SvgIcon } from './SvgIcon';
import { CableComponent, ComponentType } from '../../shared/types';
import { generateKey, } from '../../shared/services/Utils';
import { useCable } from '../../cable/CableContext';
import {
    defaultBraidProperties,
    defaultFoilProperties,
    defaultJacketProperties,
    defaultTwistedProperties,
    defaultWireProperties
} from '../../properties/LookupTable';
import { useApp } from '../../../app/AppContext';
import { useMemo } from 'react';
import { findNodes, wrapSelectedNodes } from '../../shared/services/TreeDataManager';

interface Defaults {
    id: string;
    value: string;
}

const buttonStyle = `${iconBtnDarkSquareCss} pb-2 pt-4 gap-2 inline-flex flex-col items-center border-b border-white border-opacity-10`;

export function ComponentsBar(): JSX.Element {
    const {state: appState, dispatch: dispatchState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();

    const foilButtonDisabled = useMemo((): boolean => {
        if (appState.selectedCompIds.length === 0) return true;
        const selectedNodes = findNodes(appState.selectedCompIds, cableState);
        const childElements = selectedNodes.filter(node => node.type === ComponentType.WIRE || node.type === ComponentType.TWISTED_PAIR);
        return childElements.length < 1;
    }, [appState.selectedCompIds, cableState]);

    const twistedButtonDisabled = useMemo((): boolean => {
        if (appState.selectedCompIds.length < 2) return true;
        const selectedNodes = findNodes(appState.selectedCompIds, cableState);
        const wires = selectedNodes.filter(node => node.type === ComponentType.WIRE);
        return wires.length !== 2;
    }, [appState.selectedCompIds, cableState]);

    function addComponent(defaults: Partial<CableComponent>, defaultProperties: CableComponent) {
        dispatchCable({
            type: 'add component',
            payload: [{
                ...defaultProperties,
                ...defaults,
                chartData: {
                    ...defaultProperties.chartData,
                    compAbsolutePos: appState.axisCenter
                }
            }]
        });
    }

    function handleAdd(type: ComponentType) {
        const id = `${type.replaceAll(' ','-')}-${generateKey()}`;
        const defaults: Defaults = {id, value: id};
        dispatchState({type: 'set config', payload: {selectedCompIds: [id]}});

        switch (type) {
            case ComponentType.WIRE:
                addComponent(defaults, defaultWireProperties);
                break;
            case ComponentType.BRAID:
                addComponent(defaults, defaultBraidProperties);
                break;
            case ComponentType.JACKET:
                addComponent(defaults, defaultJacketProperties);
                break;
            case ComponentType.FOIL:
            case ComponentType.TWISTED_PAIR:
                const defaultProperties = (type === ComponentType.FOIL) ?
                    defaultFoilProperties : defaultTwistedProperties;
                const wrapperComponent = wrapSelectedNodes({
                    defaults,
                    defaultProperties,
                    dataTree: cableState,
                    ...appState
                });
                if (!wrapperComponent) return;
                dispatchCable({ type: 'update cable', payload: wrapperComponent});
                break;
        }
    }


    return (
        <div className={`w-[6.3rem] bg-fs-dark-800 flex flex-col justify-between`}>
            <div className={`flex-1 flex flex-col`}>
                <button
                    onClick={() => handleAdd(ComponentType.WIRE)}
                    className={buttonStyle}
                >
                    <SvgIcon type={ComponentType.WIRE} />
                    <label>Wire</label>
                </button>
                <button
                    onClick={() => handleAdd(ComponentType.BRAID)}
                    className={buttonStyle}
                >
                    <SvgIcon type={ComponentType.BRAID} />
                    <label>Braid</label>
                </button>
                <button
                    onClick={() => handleAdd(ComponentType.JACKET)}
                    className={buttonStyle}
                >
                    <SvgIcon type={ComponentType.JACKET} />
                    <label>Jacket</label>
                </button>
                <button
                    onClick={() => handleAdd(ComponentType.FOIL)}
                    className={buttonStyle}
                    disabled={foilButtonDisabled}
                >
                    <SvgIcon type={ComponentType.FOIL} />
                    <label>Foil</label>
                </button>
                <button
                    onClick={() => handleAdd(ComponentType.TWISTED_PAIR)}
                    className={buttonStyle}
                    disabled={twistedButtonDisabled}
                >
                    <SvgIcon type={ComponentType.TWISTED_PAIR} />
                    <label>Twisted Pair</label>
                </button>
            </div>

            <div className={`flex flex-col items-center justify-center pb-4`}>
                <img src={Logo} alt="Frisimos Technologies LTD." className={`h-[24px]`} />
                <div className={`text-xs p-2`}>{`${process.env.REACT_APP_VERSION}`}</div>
            </div>
        </div>
    )
}

import { JSX, useMemo, useState } from 'react';
import {
    MdImage,
    MdInsertDriveFile,
    MdOutlineContentCopy,
    MdOutlineExpandMore,
    MdOutlineGrid4X4,
    MdOutlineZoomIn,
    MdOutlineZoomOut,
} from 'react-icons/md';
import { TbAxisX } from 'react-icons/tb';
import { activeButton, iconBtnDarkCss, iconBtnFlexCss } from '../../../theme/css/button';
import { useApp } from '../../../app/AppContext';
import { ButtonMenu } from '../../shared/components/ButtonMenu';
import { AppState, ButtonMenuItem, CableComponent, ComponentType, ImportJson } from '../../shared/types';
import { chartCfg } from '../../cable/CableChartConfig';
import { generateKey, handleException, isConvex } from '../../shared/services/Utils';
import { useCable } from '../../cable/CableContext';
import { findNodes, normalizeExportData, normalizeImportData } from '../../shared/services/TreeDataManager';
import { useDialogManager } from '../../shared/hooks/useDialogManager';
import { Modal } from '../../shared/components/Modal';
import { ImportForm, ImportFormData } from './ImportForm';

type FileExtension = 'json' | 'jpg';

function downloadFile(url: string, fileExtension: FileExtension) {
    const link = document.createElement('a');
    const d = new Date();
    link.download =  `frisimos-cable-designer-${d.getTime()}.${fileExtension}`;
    link.href = url;
    link.click();
}

function loadImage(url: string): Promise<HTMLImageElement> {
    const imgEl = document.createElement('img');
    imgEl.src = url;

    return new Promise((resolve, reject) => {
        imgEl.onload = () => resolve(imgEl);
        imgEl.onerror = reject;
        imgEl.src = url;
    })
}

function exportCableAsJson(cableData: Array<CableComponent>, appState: AppState) {
    const data = { components: normalizeExportData(cableData, appState, cableData) };
    const fileData = JSON.stringify(data);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    downloadFile(url, 'json');
}

async function exportCableAsImage() {
    // Extract svg as xml data string.
    const svg = document.getElementById(chartCfg.selector);
    if (!svg) return;
    const cloned = svg.cloneNode(true);
    // remove axis, grid...
    for (let i=cloned.childNodes.length-1, len=0 ; i>=len ; i--) {
        const child = cloned.childNodes[i];
        const node = (new XMLSerializer()).serializeToString(child);
        if (child.nodeName === 'line' ||
            node.includes(chartCfg.axis.selector) ||
            node.includes(chartCfg.cssSelector.grid)) {
            cloned.removeChild(child);
        }
    }
    const svgAsXML = (new XMLSerializer()).serializeToString(cloned);
    const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`;

    // Convert the img element to dataURL using a canvas element
    try {
        const canvas = document.createElement('canvas')
        if (!canvas) return;
        canvas.width = svg.clientWidth
        canvas.height = svg.clientHeight
        const context = canvas.getContext('2d');
        if (!context) return;

        const img: HTMLImageElement = await loadImage(svgData);
        context.drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight)
        const imgFormat = 'jpg';
        const url = canvas.toDataURL(`image/${imgFormat}`, 1.0);
        downloadFile(url, imgFormat);
    }
    catch(e) {
        handleException(e as string);
    }
}

function Separator(): JSX.Element {
    return (
        <div className={`border-l border-pickled-bluewood-100/30`} />
    )
}

function calcRelativePos(
    items: Array<CableComponent>,
    parent: CableComponent|null,
    appState: AppState,
    group?: CableComponent
): Array<CableComponent> {
    return items.flatMap(node => {
        const { chartData, depth, properties: {x, y} } = node;
        const { compAbsolutePos } = chartData;
        let newChartData = { ...chartData };

        if (parent && depth >= 1 && !chartData.groupRelativePos) { // inside a group
            const groupAbsolutePos = group ? group.chartData.compAbsolutePos : [0,0];
            let dx = compAbsolutePos[0] - groupAbsolutePos[0];
            let dy = compAbsolutePos[1] - groupAbsolutePos[1];

            if (!isConvex(node) && appState.axisX && appState.axisY && typeof x === 'number' && typeof y === 'number') {
                dx = appState.axisX(x) - groupAbsolutePos[0];
                dy = appState.axisY(y) - groupAbsolutePos[1];
            }

            newChartData = {
                ...chartData,
                groupRelativePos: [dx, dy],
            }
        }

        return {
            ...node,
            chartData: {
                ...newChartData
            },
            ...(node.children && {children: calcRelativePos(node.children, node, appState, (group || node) )})
        }
    })
}

export function TopBar(): JSX.Element {
    const {state: appState, dispatch: dispatchState} = useApp();
    const {state: cableState, dispatch: dispatchCable} = useCable();
    const [gridBtnActive, setGridBtnActive] = useState<boolean>(appState.showGrid);
    const [axesBtnActive, setAxesBtnActive] = useState<boolean>(appState.showAxes);
    const [disableZoom, setDisableZoom] = useState<boolean>(false);
    const {open, handleOpen, handleClose} = useDialogManager();

    const duplicateDisabled = useMemo((): boolean => {
        if (appState.selectedCompIds.length !== 1) return true;
        const selectedNodes = findNodes(appState.selectedCompIds, cableState);
        const wires = selectedNodes.filter(node => node.type === ComponentType.WIRE);
        return wires.length !== 1;
    }, [appState.selectedCompIds, cableState]);

    const exportMenuItems: Array<ButtonMenuItem> = [
        {
            label: 'Export as image',
            icon: <MdImage />,
            onClick: exportCableAsImage
        },
        {
            label: 'Export as JSON',
            icon: <MdInsertDriveFile />,
            onClick: () => exportCableAsJson(cableState, appState)
        }
    ];

    function handleToggleGrid() {
        const gridActive = !gridBtnActive;
        setGridBtnActive(gridActive);
        dispatchState({type: 'set config', payload: {showGrid: gridActive}});
    }

    function handleToggleAxes() {
        const axesActive = !axesBtnActive;
        setAxesBtnActive(axesActive);
        dispatchState({type: 'set config', payload: {showAxes: axesActive}});
    }

    function handleZoom(scale: number) {
        dispatchState({type: 'set config', payload: {zoom: appState.zoom + scale}});
        setDisableZoom(true);
    }

    function handleCancel() {
        handleClose();
    }

    function handleDuplicate() {
        const selectedNodes = findNodes(appState.selectedCompIds, cableState);
        if (selectedNodes.length === 0) return;
        const node = selectedNodes[0];
        const id = `${node.type.replaceAll(' ','-')}-${generateKey()}`;

        dispatchCable({
            type: 'add component',
            payload: [{
                ...node,
                id,
                value: id,
                type: ComponentType.WIRE,
                properties: {
                    ...node.properties,
                    wire: { ...node.properties.wire },
                    manually_edit_coordinates: false,
                },
                nodes: [...node.nodes],
                chartData: {
                    compAbsolutePos: [0, 0],
                },
                depth: 0,
                children: [],
            }]
        });
    }

    function handleImport(data: ImportFormData) {
        const cableData: ImportJson = JSON.parse(data.json);
        const normalizedData = normalizeImportData(cableData.components, 0, appState);
        if (!normalizedData[0].hasOwnProperty('type')) return;
        const cable = normalizedData as Array<CableComponent>;
        const payload = calcRelativePos(cable, null, appState);
        dispatchCable({ type: 'update cable', payload });
        handleClose();
    }


    return (
        <>
            <header className={`bg-pickled-bluewood-500 flex justify-between p-2`}>
                <div>
                    Cable Designer
                </div>
                <div className={`flex gap-2`}>
                    <button
                        className={`${iconBtnDarkCss} ${gridBtnActive ? activeButton : ''}`}
                        onClick={handleToggleGrid}
                    >
                        <MdOutlineGrid4X4 size={20} />
                    </button>
                    <button
                        className={`${iconBtnDarkCss} ${axesBtnActive ? activeButton : ''}`}
                        onClick={handleToggleAxes}
                    >
                        <TbAxisX size={20} />
                    </button>

                    <Separator />

                    <button
                        onClick={() => handleZoom(1)}
                        className={iconBtnDarkCss}
                        disabled={disableZoom}
                    >
                        <MdOutlineZoomIn size={20} />
                    </button>
                    {/*<button*/}
                    {/*    onClick={() => handleZoom(-1)}*/}
                    {/*    className={iconBtnDarkCss}*/}
                    {/*>*/}
                    {/*    <MdOutlineZoomOut />*/}
                    {/*</button>*/}
                    <button
                        disabled={true}
                        className={iconBtnDarkCss}
                    >
                        <MdOutlineZoomOut size={20} />
                    </button>

                    <Separator />

                    <button
                        onClick={handleDuplicate}
                        className={iconBtnDarkCss}
                        disabled={duplicateDisabled}
                    >
                        <MdOutlineContentCopy />
                    </button>

                    <button
                        onClick={handleOpen}
                        className={iconBtnFlexCss}
                    >
                        Import...
                    </button>

                    <ButtonMenu
                        buttonLabel={
                            <>Export <MdOutlineExpandMore /></>
                        }
                        menuItems={exportMenuItems}
                    />
                </div>
            </header>

            <Modal
                open={open}
                onClose={handleCancel}
                title={`Import Cable`}
            >
                <ImportForm
                    onSubmit={handleImport}
                    onClose={handleClose}
                />
            </Modal>
        </>
    )
}

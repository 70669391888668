import * as React from 'react';
import { ReactNode } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export interface DialogTitleProps {
    title: string;
    open: boolean;
    onClose: () => void;
    children?: ReactNode;
    [key: string]: any;
}

export function Modal(props: DialogTitleProps) {
    const {children, onClose, open, title, ...other} = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="bg-black bg-opacity-25"
            {...other}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                },
            }}
        >
            <DialogTitle className="bg-big-stone-500 py-0">
                <div className="text-white">
                    {title}
                </div>

                <button
                    onClick={onClose}
                    aria-label="close"
                    className="absolute top-5 right-5 text-white"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-inherit"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
            </DialogTitle>

            <div className="flex-1 bg-big-stone-500 text-white">
                {children}
            </div>
        </Dialog>
    );
}

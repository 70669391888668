import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';

interface Props {
    container: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    pixelsPerMm: number;
}

export function Foil({
    container,
    component,
    pixelsPerMm,
}: Props) {
    const {properties, chartData} = component;
    if (!chartData.hullData) return;

    const {thickness, colors} = properties;
    const color = colors ? colors[0] : '';
    const foilThickness = thickness ? thickness * pixelsPerMm : 0;

    const hull = d3.polygonHull(chartData.hullData);
    if (!hull) return;

    const path = 'M' + hull.join('L') + 'Z';

    container
    .append('path')
    .attr('d', path)
    .attr('class', `${chartCfg.cssSelector.foil}`)
    .attr('fill', 'none')
    .attr('stroke', color)
    .attr('stroke-width', foilThickness)
    .attr('stroke-location', 'outside')
    .attr('stroke-linejoin', 'round');
}

import { Selection } from 'd3-selection';
import { circlePosition, getStrandDiameter, strandValuesPerLevel } from '../../shared/services/Utils';
import { CableComponent } from '../../shared/types';
import { chartCfg } from '../CableChartConfig';
import * as d3 from 'd3';

interface Props {
    container: Selection<SVGSVGElement, CableComponent, any, any>;
    component: CableComponent;
    pixelsPerMm: number;
    tree: Array<CableComponent>,
}

export function Wire({
    container,
    component,
    pixelsPerMm,
    tree,
}: Props) {
    const { wire } = component.properties;
    const fillColor = wire && wire.copper_color ? wire.copper_color : '#d07f39';

    component.nodes.forEach(node => {
        const { radius, x, y } = node;
        const nodePos: [number, number] = [x * pixelsPerMm, y * pixelsPerMm];
        const position = circlePosition(component, nodePos, tree);

        container
        .append('circle')
        .attr('cx', position[0])
        .attr('cy', position[1])
        .attr('r',  pixelsPerMm * radius)
        .attr('class', `${chartCfg.cssSelector.wire}`)
        .attr('fill', fillColor)
    })

    WireInsulation({
        container,
        component,
        pixelsPerMm,
        tree,
    })
}

function WireInsulation({
    container,
    component,
    pixelsPerMm,
    tree,
}: Props) {
    const { properties: { wire, colors }} = component;
    if (!wire || !colors) return;
    const { outer_insulation_diameter, strands_total } = wire;
    if ( !outer_insulation_diameter || +outer_insulation_diameter === 0) return;

    const middleStrandData = component.nodes[0];
    const nodePos: [number, number] = [middleStrandData.x * pixelsPerMm, middleStrandData.y * pixelsPerMm];
    const position = circlePosition(component, nodePos, tree);

    const totalStrands = strands_total ? +strands_total : 0;
    const strandValues = strandValuesPerLevel(totalStrands);
    if (!strandValues) return;
    const strandDiameter = getStrandDiameter(wire);
    const innerDiameter = strandValues.diameterMultiply * strandDiameter;
    const outerDiameter = +outer_insulation_diameter;
    const innerRadius = (innerDiameter / 2) * pixelsPerMm;
    const outerRadius = (outerDiameter / 2) * pixelsPerMm;

    const arc: any = d3.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

    const pie = d3.pie()
    .padAngle(0)
    .value((d:any) => d.value);

    const insulationData: Array<(any | number | { valueOf(): number; })> =
        !outerDiameter ? [] : [{name: 'color1', value: outerRadius}, {name: 'color2', value: outerRadius}];
    const color2 = colors[1] ? colors[1] : colors[0];
    const color =
        d3.scaleOrdinal()
        .domain(insulationData.map((d:any) => d.name))
        .range([colors[0], color2]);

    container
    .selectAll()
    .data(pie(insulationData))
    .enter()
    .append('path')
    // @ts-ignore
    .attr('fill', (d:any) => color(d.data.name))
    .attr('d', arc)
    .attr('transform', `translate(${position[0]},${position[1]})`)
    .attr('class', chartCfg.cssSelector.insulation)
    .exit().remove()
}


